import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import Style from './index.module.scss'

const Page = () => (
  <div className={Style.markdownBody} style={{ margin: '16px' }}>
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>TermsOfService</title>
      <link
        rel="icon"
        type="image/x-icon"
        href={`${withPrefix('/')}img/recipe-note-icon.png`}
      />
    </Helmet>
    <h1>Help</h1>
    <section>
      <h2>I can't save a web recipe image</h2>
      <p>
        Special images such as videos and advertisements may not be imported.
        Try the following:
      </p>
      <ol>
        <li>Scroll the web page to see all the images in the web page.</li>
        <li>Save the recipe.</li>
      </ol>
    </section>
    <section>
      <h2>Web recipe ingredients are not imported automatically.</h2>
      <p>
        Depending on the site, the automatic material import function may not be
        supported. Please wait for support of the target website.
      </p>
    </section>
    <section>
      <h2>Is there a backup function?</h2>
      <p>
        You can use the backup function using Google Drive. Make sure that the
        backup destination Google Drive has enough space to store the recipe
        data, and then back up. You can know the data storage capacity from the
        application information of the terminal.
      </p>
    </section>
    <section>
      <h2>Where is the backed up data in Google Drive?</h2>
      <p>
        It is saved in the storage location dedicated to RECIPE NOTE of Google Drive.
        The saved data is hidden and cannot be viewed.
        You can only view and delete the stored data size.
        Please refer to the procedure below.
      </p>
      <ol>
        <li>
          On your computer, go to drive.google.com
        </li>
        <li>Click Settings Settings and then Settings.</li>
        <li>Click Manage apps.</li>
      </ol>
    </section>
    <section>
      <h2>A communication error occurred during backup / restore. After that, communication errors frequently occur. What should I do?</h2>
      <p>
      Please follow the steps below.
      </p>
      <ol>
        <li>Log out of the logged-in account. Log in again.</li>
        <li>Back up or restore again.</li>
        <li>Keep the Backup / Restore screen open.</li>
      </ol>
    </section>
    <section>
      <h2>Contact Us</h2>
      <p>
        For inquiries regarding this app, please email
        <a href="mailto:makitsystem@gmail.com">makitsystem@gmail.com</a>
      </p>
    </section>
  </div>
)
export default Page
